<template>
  <div class="back-ground-remover-wrap">

    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
    </div>

    <div class="effects-container" v-if="!currentSelectEffect">
      <h3 class="result-title">Choose an effect</h3>
      <div class="effect-box">
        <div class="effect-item" v-for="item in effectsList" :key="item.id"
          @mouseenter="(e) => handleMouseEnter(e, item)" @mouseleave="(e) => handleMouseLeave(e, item)">
          <img :src="item.effectUrl" />
          <custom-btn v-if="item.showUseBtn" class="custom-btn"
            :customStyle="{ width: '138px', height: '42px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF', 'margin-bottom': '16px' }"
            @click="(e) => handleUse(e, item)">Use it
          </custom-btn>
        </div>
      </div>
    </div>

    <!-- 特效展示区域 -->
    <div class="image-show-container" v-if="currentSelectEffect">
      <div v-if="innerImageList && innerImageList.length === 1">
        <div class="flex justify-center" v-for="(item, index) in innerImageList" :key="index">
          <div class="flex-left">
            <div class="result-container container">
              <h3 class="result-title">Wink</h3>
              <div class="position-box">
                <div class="result-item-box">
                  <div class="image-box result-image-box" style="width: 560px; height: 560px;">
                    <image-board ref="resultBoardRef" :srcData="item.removeBgResult" :isModelDone="isModelDone"
                      v-if="item.removeBgResult && !showOriginal" />
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone"
                      v-else-if="item.originImageData && showOriginal" />
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone"
                      v-else />
                  </div>
                </div>
                <div class="mask-box"></div>
              </div>
              <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate1" />
            </div>
          </div>
        </div>
      </div>
      <div class="spin-container" v-else>
        <a-spin />
      </div>
    </div>
  </div>
</template>

<script setup>
import BackBtn from '@/components/boolv-ui/back-btn'
import { ref, defineProps, watchEffect, defineEmits } from "vue";
import CommonRate from "@/components/common-rate.vue";
import ImageBoard from '@/components/image-board';
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import effect from '@/assets/3d-effect/effect.png'
const emits = defineEmits(['back'])

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
  imageList: Array,
});

const innerImageList = ref([])
const clearRate = ref(false)
const currentSelectEffect = ref(null)
const effectsList = ref([
  {
    id: 1,
    effectName: '111',
    effectUrl: effect
  },
  {
    id: 2,
    effectName: '222',
    effectUrl: effect
  }
])

watchEffect(() => {
  if (props.imageList && props.imageList.length) {
    innerImageList.value = props.imageList
  }
})


const handleBack = () => {
  emits('back')
}

const handleMouseEnter = (e, item) => {
  item.showUseBtn = true
}

const handleMouseLeave = (e, item) => {
  item.showUseBtn = false
}

const handleUse = (e, item) => {
  currentSelectEffect.value = item
}
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  min-height: calc(100vh - 60px);
  padding: 0 32px;
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {
  min-height: calc(100vh - 100px);
}

.flex-left {
  // width: 560px;
  // padding: 61px 34px;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}

.flex-right {
  padding: 61px 32px 37.63px 18px;
  // width: 560px;
}

.backGroundRemovers-container {
  // padding: 0 32px;
  width: 100%;
  min-height: calc(100vh - 60px);
  display: grid;
}


.result-item-box {
  position: relative;
  width: 560px;
  height: 560px;
}

.result-container {
  width: 100%;
  height: 100%;
}


.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}


.result-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.effects-container {
  margin-top: 111px;
}

.position-box {
  position: relative;
  z-index: 1;

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
  }

  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }

  .mask-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.3;
    // background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;

  }


  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }


  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  width: 560px;
  height: 560px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}



.backend-selector {
  width: 200px;
}


.back-btn-container {
  padding-top: 25px;
}


.spin-container {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.effect-item {
  width: 300px;
  height: 300px;
  position: relative;

  img {
    border-radius: 4px;
  }

  .custom-btn {
    position: absolute;
    bottom: 24px;
    left: 50%;
    margin-left: -69px;
  }
}

.effect-box {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 300px);
  grid-row-gap: 36px;
  grid-column-gap: 20px;
  padding-top: 18px;
  justify-content: center;

}
</style>
