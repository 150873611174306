<template>
  <div>
    <!-- 3D运镜 -->
    <effect v-if="uploadComplete" :imageList="uploadImageList" @back="handleBack" />
    <bg-remove-upload @uploadComplete="handleUploadComplete" v-else />
  </div>
</template>
<script setup>
import bgRemoveUpload from './image-upload.vue'
import effect from './3d-effect.vue'
import { ref } from 'vue'
const uploadComplete = ref(false)
const uploadImageList = ref([])

const handleUploadComplete = (imageList) => {
  uploadComplete.value = true
  uploadImageList.value = imageList
}

const handleBack = () => {
  uploadImageList.value = []
  uploadComplete.value = false
}
</script>

<style lang="less">

</style>